import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import useNotification, {
  DROPDOWN_NOTIFICATION_DESCRIPTION_MAX_NUMBER_OF_CHARACTERS,
  NOTIFICATION_TITLE_MAX_NUMBER_OF_CHARACTERS,
} from 'features/useNotification';
import {NotificationDropdown, NotificationList} from 'letrus-ui';
import moment from 'moment';
import 'moment/locale/pt-br';
import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  fetchNotificationsRequest,
  getNotifications,
  getUnseenNotificationCount,
  getUpdateNotificationSuccess,
  updateNotificationByIdRequest,
} from 'store/reducers/notifications';
import {ApplicationState} from 'store/rootReducer';
import {useMobile} from 'utils/hooks';

moment.locale('pt-br');

interface StateProps {
  notifications: LetrusApi.NotificationLog[];
  unseenNotificationCount: number;
  updateNotificationSuccess: boolean;
}

interface DispatchProps {
  updateNotificationByIdRequest: typeof updateNotificationByIdRequest;
  fetchNotificationsRequest: typeof fetchNotificationsRequest;
}

export type DropdownNotificationsProps = StateProps & DispatchProps;

const DropdownNotifications: React.FC<DropdownNotificationsProps> = ({
  notifications,
  unseenNotificationCount,
  updateNotificationByIdRequest,
  updateNotificationSuccess,
  fetchNotificationsRequest,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const history = useHistory();
  const {getLinkType, hasBeenSeen, getNotificationSeenState} =
    useNotification();
  const {isMobile} = useMobile();

  useEffect(() => {
    if (notifications.length === 0) {
      fetchNotificationsRequest();
    }
  }, [notifications.length]);

  useEffect(() => {
    if (updateNotificationSuccess) {
      fetchNotificationsRequest();
    }
  }, [updateNotificationSuccess]);

  const notificationList = notifications.slice(0, 5).map((notification) => {
    const time = moment(notification.created).fromNow();

    return {
      id: notification.id,
      title:
        notification.title?.substring(
          0,
          NOTIFICATION_TITLE_MAX_NUMBER_OF_CHARACTERS,
        ) ?? '',
      description: notification.content.substring(
        0,
        DROPDOWN_NOTIFICATION_DESCRIPTION_MAX_NUMBER_OF_CHARACTERS,
      ),
      time,
      opened: hasBeenSeen(notification),
      handleClick: () => onClickNotification(notification),
      withHeaderIcon: !!notification.link,
    };
  });

  function onClickNotification(notification: LetrusApi.NotificationLog) {
    const notificationSeenState = getNotificationSeenState(notification);
    const notificationLinkType = getLinkType(notification);

    if (notificationSeenState === 'not-received') {
      updateNotificationByIdRequest(notification.id, {
        received: new Date().toISOString(),
      } as any);
    }

    if (notificationSeenState === 'not-opened') {
      updateNotificationByIdRequest(notification.id, {
        opened: new Date().toISOString(),
      } as any);
    }

    if (!notification.link) {
      return;
    }

    if (notificationLinkType === 'internal') {
      history.push(notification.link);
    }

    if (notificationLinkType === 'external') {
      window.open(notification.link, '_blank');
    }
  }

  function onClickNotificationHistory() {
    if (notifications.length >= 1) {
      setIsDropdownOpen(false);
      history.push('/notificacoes');
    }
  }

  function onClickNotificationButton() {
    if (isMobile) {
      onClickNotificationHistory();
    } else {
      setIsDropdownOpen(!isDropdownOpen);
    }

    const notReceivedYetNotifications = notifications.filter(
      (notification) => !notification.received,
    );

    if (notReceivedYetNotifications.length) {
      notReceivedYetNotifications.forEach((notification) => {
        updateNotificationByIdRequest(notification.id, {
          received: new Date().toISOString(),
        });
      });
    }
  }

  return (
    <div className="Notifications" data-testid="notification-button">
      {/* TODO: it will be changed for the new design */}
      <NotificationDropdown
        notificationState={unseenNotificationCount ? 'new' : 'none'}
        onClickSeeMore={onClickNotificationHistory}
        isDropdownOpen={isDropdownOpen}
        onClickNotificationButton={onClickNotificationButton}
        onClickOutsideDropdown={() => setIsDropdownOpen(false)}
        notificationCount={unseenNotificationCount}
      >
        {!!notifications.length && (
          <NotificationList items={notificationList} />
        )}
      </NotificationDropdown>
    </div>
  );
};

export default connect(
  (state: ApplicationState) => ({
    notifications: getNotifications(state),
    unseenNotificationCount: getUnseenNotificationCount(state),
    updateNotificationSuccess: getUpdateNotificationSuccess(state),
  }),
  {
    fetchNotificationsRequest,
    updateNotificationByIdRequest,
  },
)(DropdownNotifications);
